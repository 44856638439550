import * as React from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";

import AppBar from "@mui/material/AppBar";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import Container from "@mui/material/Container";

import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

import QrCodeScannerOutlinedIcon from "@mui/icons-material/QrCodeScannerOutlined";
import ArrowDropDownOutlinedIcon from "@mui/icons-material/ArrowDropDownOutlined";
import ArrowDropUpOutlinedIcon from "@mui/icons-material/ArrowDropUpOutlined";
import CreditScoreOutlinedIcon from "@mui/icons-material/CreditScoreOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import EventNoteOutlinedIcon from "@mui/icons-material/EventNoteOutlined";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import Inventory2OutlinedIcon from "@mui/icons-material/Inventory2Outlined";
//import KeyOutlinedIcon from "@mui/icons-material/KeyOutlined";
import LinkOutlinedIcon from "@mui/icons-material/LinkOutlined";
import LoginOutlinedIcon from "@mui/icons-material/LoginOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import PendingActionsOutlinedIcon from "@mui/icons-material/PendingActionsOutlined";
import PointOfSaleOutlinedIcon from "@mui/icons-material/PointOfSaleOutlined";
import ReceiptLongOutlinedIcon from "@mui/icons-material/ReceiptLongOutlined";
import RequestQuoteOutlinedIcon from "@mui/icons-material/RequestQuoteOutlined";
import RoomPreferencesOutlinedIcon from "@mui/icons-material/RoomPreferencesOutlined";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import SettingsSuggestOutlinedIcon from "@mui/icons-material/SettingsSuggestOutlined";
import TaskOutlinedIcon from "@mui/icons-material/TaskOutlined";
import UploadFileOutlinedIcon from "@mui/icons-material/UploadFileOutlined";
import VideoFileOutlinedIcon from "@mui/icons-material/VideoFileOutlined";
import CollectionsOutlinedIcon from "@mui/icons-material/CollectionsOutlined";

import MenuIcon from "@mui/icons-material/Menu";
import Stack from "@mui/material/Stack";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

import ThemeSwitch from "../components/ThemeSwitch";

import { SettingsContext } from "../index";
import { ThemeContext } from "../App";

const drawerWidth = 225;

const Icons = {
  "Counter Tickets": DescriptionOutlinedIcon,
  Charges: CreditScoreOutlinedIcon,
  Home: HomeOutlinedIcon,
  Login: LoginOutlinedIcon,
  Logout: LogoutOutlinedIcon,
  OMS: RoomPreferencesOutlinedIcon,
  "Opening Info": QrCodeScannerOutlinedIcon,
  "Payment Report": RequestQuoteOutlinedIcon,
  "Purchasing Portal": Inventory2OutlinedIcon,
  Projects: VideoFileOutlinedIcon,
  Quotes: UploadFileOutlinedIcon,
  "Service Requests": EventNoteOutlinedIcon,
  "Staff Search": SearchOutlinedIcon,
  "System Importer": SettingsSuggestOutlinedIcon,
  "Task Tracker": PendingActionsOutlinedIcon,
  "Ticket Editor": PointOfSaleOutlinedIcon,
  "Work Orders": TaskOutlinedIcon,
  "Key Receipts": ReceiptLongOutlinedIcon,
  "Tech Spotlight": CollectionsOutlinedIcon,
};

const ResponsiveAppBar = ({
  currentUserInfo,
  isLoggedIn,
  isLightTheme,
  setIsLightTheme,
}) => {
  const settings = React.useContext(SettingsContext);
  const theme = React.useContext(ThemeContext);
  const location = useLocation();
  const settingsPages = settings.mainPages;
  const settingsAdditionalPages = settings.additionalPages;
  let settingsAccount = settings.accountPages;

  if (!isLoggedIn) {
    settingsAccount = ["Login"];
  }

  const logo = settings?.logo ? "/img/" + settings.logo : null;
  const [userOpen, setUserOpen] = React.useState(false);
  const [navOpen, setNavOpen] = React.useState(false);

  const handleOpenNavMenu = React.useCallback(() => {
    setNavOpen((p) => !p);
    setUserOpen(false);
  }, []);

  const handleOpenUserMenu = React.useCallback(() => {
    setUserOpen((p) => !p);
    setNavOpen(false);
  }, []);

  const handleCloseNavMenu = React.useCallback(() => {
    setNavOpen(false);
    setUserOpen(false);
    window.scrollTo(0, 0);
  }, []);

  const handleCloseUserMenu = React.useCallback(() => {
    setUserOpen(false);
    setNavOpen(false);
  }, []);

  const navigate = useNavigate();

  React.useEffect(() => {
    if (currentUserInfo) {
      if (
        currentUserInfo?.Email &&
        (currentUserInfo?.role === "Staff" || currentUserInfo?.role === "Admin")
      ) {
        if (settings?.staffPages) {
          settings?.staffPages.forEach((element) => {
            settingsAccount.unshift(element);
          });
        }
      }
    }

    return () => {
      if (settings?.staffPages) {
        settings?.staffPages.forEach((element) => {
          const index = settingsAccount.indexOf(element);
          if (index > -1) {
            settingsAccount.splice(index, 1);
          }
        });
      }
    };
  }, [settings, settingsAccount, settingsPages, currentUserInfo]);

  return (
    <>
      <Box
        sx={{
          position: "relative",
          top: "-15px",
          displayPrint: "flex",
          display: "none",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          alignSelf: "center",
          justifySelf: "center",
          m: "0",
          p: "0",
        }}
        className="Print-Show"
      >
        {logo && <img src={logo} alt="Logo" height="75px" width="auto"></img>}
        <Typography
          variant="body2"
          component="p"
          sx={{
            fontSize: "10px",
            color: theme.palette.TopBar.contrastText,
            textAlign: "center",
            mt: "2.5px",
          }}
        >
          {settings.companyAddress.lineTwo}
          {", "}
          {settings.companyAddress.lineThree}
        </Typography>
      </Box>
      <AppBar
        position="fixed"
        sx={{
          bgcolor: "TopBar.main",
          top: "0",
          borderBottomLeftRadius: 15,
          borderBottomRightRadius: 15,
          height: "65px",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        className="Print-Hide"
      >
        <Container
          maxWidth={false}
          sx={{
            displayPrint: "none",
            height: "65px",
            minHeight: "65px",
          }}
          className="Print-Hide"
        >
          <Toolbar disableGutters sx={{ height: "65px", minHeight: "65px" }}>
            <Box sx={{ flexGrow: 12, display: { xs: "none", md: "flex" } }}>
              {logo && (
                <Link
                  to="/"
                  style={{ width: "150px", margin: "0px", padding: "0px" }}
                  onClick={() => {
                    handleCloseNavMenu();
                    handleCloseUserMenu();
                  }}
                >
                  <img src={logo} alt="Logo" height="50px" width="auto"></img>
                </Link>
              )}
            </Box>
            <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
              <ButtonGroup variant="text" aria-label="nav button group">
                {settingsPages.map((page) => {
                  const PageIcon = Icons?.[page];
                  let link = "/" + page.replace(/ /g, "").toLowerCase();
                  let Match =
                    location.pathname ===
                    `${"/" + page.replace(/ /g, "").toLowerCase()}`;
                  return (
                    <Button
                      key={page}
                      onClick={() => {
                        handleCloseNavMenu();
                        navigate(link);
                      }}
                      sx={{
                        my: 2,
                        display: "flex",
                        m: "0",
                        backgroundColor: Match
                          ? theme.palette.primary.main
                          : theme.palette.TopBar.buttonColor,
                        color: Match
                          ? theme.palette.primary.contrastText
                          : theme.palette.TopBar.contrastText,
                        "&:hover": {
                          color: theme.palette.TopBar.contrastText,
                        },
                        fontSize: "12px",
                      }}
                      startIcon={PageIcon ? <PageIcon /> : null}
                    >
                      {page}
                    </Button>
                  );
                })}
                {settingsAdditionalPages.map((page) => {
                  const PageIcon = Icons?.[page?.label];
                  return (
                    <>
                      {page?.label !== "" ? (
                        <Button
                          key={page.label}
                          onClick={() => {
                            handleCloseNavMenu();
                            window.location.href = page.link;
                          }}
                          startIcon={PageIcon ? <PageIcon /> : null}
                          sx={{
                            my: 2,
                            display: "flex",
                            m: "0",
                            backgroundColor: theme.palette.TopBar.buttonColor,
                            fontSize: "12px",
                          }}
                        >
                          {page.label}
                        </Button>
                      ) : (
                        <></>
                      )}
                    </>
                  );
                })}
              </ButtonGroup>
            </Box>
            <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                sx={{ color: theme.palette.TopBar.contrastText }}
              >
                <MenuIcon />
              </IconButton>
              <Drawer
                variant="temporary"
                anchor="left"
                open={navOpen}
                sx={{
                  minWidth: drawerWidth,
                  flexShrink: 0,
                  [`& .MuiDrawer-paper`]: {
                    width: drawerWidth,
                    boxSizing: "border-box",
                  },
                }}
                onClose={() => {
                  setUserOpen(false);
                }}
              >
                <Toolbar />
                <Box sx={{ overflow: "auto", mt: "25px" }}>
                  <Stack
                    direction={"column"}
                    spacing={0}
                    justifyContent={"center"}
                    alignItems={"center"}
                    sx={{ width: "100%" }}
                  >
                    <List>
                      {settingsPages.map((page) => {
                        const PageIcon = Icons?.[page];
                        return (
                          <ListItem key={page} disablePadding>
                            <ListItemButton
                              onClick={() => {
                                navigate(
                                  `${
                                    "/" + page.toLowerCase().replace(/ /g, "")
                                  }`
                                );
                                handleCloseNavMenu();
                              }}
                            >
                              {Icons?.[page] ? (
                                <ListItemIcon>
                                  <PageIcon color="primary" />
                                </ListItemIcon>
                              ) : (
                                <ListItemIcon>
                                  <LinkOutlinedIcon color="primary" />
                                </ListItemIcon>
                              )}
                              <ListItemText
                                primary={page}
                                sx={{ textAlign: "left" }}
                              />
                            </ListItemButton>
                          </ListItem>
                        );
                      })}
                      {settingsAdditionalPages.map((page) => {
                        const PageIcon = Icons?.[page?.label];
                        return (
                          <ListItem key={page.label} disablePadding>
                            <ListItemButton
                              onClick={() => {
                                handleCloseNavMenu();
                                window.location.href = page.link;
                              }}
                            >
                              {PageIcon ? (
                                <ListItemIcon>
                                  <PageIcon color="primary" />
                                </ListItemIcon>
                              ) : (
                                <ListItemIcon>
                                  <LinkOutlinedIcon color="primary" />
                                </ListItemIcon>
                              )}
                              <ListItemText
                                primary={page.label}
                                sx={{ textAlign: "left" }}
                              />
                            </ListItemButton>
                          </ListItem>
                        );
                      })}
                    </List>
                  </Stack>
                </Box>
              </Drawer>
            </Box>
            <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
              {logo && (
                <Link
                  to="/"
                  style={{ width: "150px", padding: "0px 8px 0px 0px" }}
                  onClick={() => {
                    handleCloseNavMenu();
                    handleCloseUserMenu();
                  }}
                >
                  <img src={logo} alt="Logo" height="50px" width="auto"></img>
                </Link>
              )}
            </Box>
            <Tooltip title="Open settings">
              <Button
                sx={{ p: "5px", flexGrow: 0 }}
                onClick={handleOpenUserMenu}
              >
                {currentUserInfo ? (
                  <Avatar
                    sx={{
                      bgcolor: theme.palette.secondary.main,
                    }}
                  >
                    {currentUserInfo?.["First Name"]
                      ? currentUserInfo?.["First Name"]
                          .substring(0, 1)
                          .toUpperCase()
                      : ""}
                    {currentUserInfo?.["Last Name"]
                      ? currentUserInfo?.["Last Name"]
                          .substring(0, 1)
                          .toUpperCase()
                      : ""}
                  </Avatar>
                ) : (
                  <Avatar alt="" src=""></Avatar>
                )}
                {!userOpen ? (
                  <ArrowDropDownOutlinedIcon
                    color={currentUserInfo ? "secondary" : "disabled"}
                    fontSize="large"
                  />
                ) : (
                  <ArrowDropUpOutlinedIcon
                    color={currentUserInfo ? "secondary" : "disabled"}
                    fontSize="large"
                  />
                )}
              </Button>
            </Tooltip>
          </Toolbar>
        </Container>
        <Drawer
          variant="temporary"
          anchor="right"
          open={userOpen}
          sx={{
            minWidth: drawerWidth,
            flexShrink: 0,
            [`& .MuiDrawer-paper`]: {
              width: drawerWidth,
              boxSizing: "border-box",
            },
          }}
          onClose={() => {
            setUserOpen(false);
          }}
        >
          <Toolbar />
          <Box sx={{ overflow: "auto", mt: "25px" }}>
            <Stack
              direction={"column"}
              spacing={0}
              justifyContent={"center"}
              alignItems={"center"}
              sx={{ width: "100%" }}
            >
              <ThemeSwitch
                isLightTheme={isLightTheme}
                setIsLightTheme={setIsLightTheme}
              />
              <List>
                {settingsAccount.map((pageLink, index) => {
                  const PageIcon = Icons?.[pageLink];
                  return (
                    <ListItem key={pageLink} disablePadding>
                      <ListItemButton
                        onClick={() => {
                          navigate(
                            `${"/" + pageLink.toLowerCase().replace(/ /g, "")}`
                          );
                          handleCloseUserMenu();
                        }}
                      >
                        {Icons?.[pageLink] ? (
                          <ListItemIcon>
                            <PageIcon color="primary" />
                          </ListItemIcon>
                        ) : (
                          <ListItemIcon>
                            <LinkOutlinedIcon color="primary" />
                          </ListItemIcon>
                        )}
                        <ListItemText
                          primary={pageLink}
                          sx={{ textAlign: "left" }}
                        />
                      </ListItemButton>
                    </ListItem>
                  );
                })}
              </List>
            </Stack>
          </Box>
        </Drawer>
      </AppBar>
    </>
  );
};

export default ResponsiveAppBar;
