import * as React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { createBrowserHistory } from "history";

import { createTheme, ThemeProvider } from "@mui/material/styles";
import Container from "@mui/system/Container";

import CircularIndeterminate from "./components/CircularIndeterminate";
import Footer from "./components/Footer";
import Nav from "./components/Nav";

import { SettingsContext } from "./index";

export const browserHistory = createBrowserHistory();
export const UserContext = React.createContext();
export const ThemeContext = React.createContext();

const PurchasingPortal = React.lazy(() =>
  import("./pages/staff/PurchasingPortal")
);
const Report = React.lazy(() => import("./pages/staff/paymentReport"));
const Review = React.lazy(() => import("./pages/Review"));
const Chart = React.lazy(() => import("./pages/Chart"));
const DailyReport = React.lazy(() => import("./pages/staff/DailyReport"));
const DocumentBinder = React.lazy(() => import("./pages/DocumentBinder"));
const FacilityEditor = React.lazy(() => import("./pages/staff/FacilityEditor"));
const AccountEditor = React.lazy(() => import("./pages/staff/AccountEditor"));
const CustomerEditor = React.lazy(() => import("./pages/staff/CustomerEditor"));
const FAQ = React.lazy(() => import("./pages/FAQ"));
const Home = React.lazy(() => import("./pages/Home"));
const KeyReceipts = React.lazy(() => import("./pages/KeyReceipts"));
const Login = React.lazy(() => import("./pages/Login"));
const OMSFacilities = React.lazy(() => import("./pages/OMSFacilities"));
const TaskTracker = React.lazy(() => import("./pages/staff/TaskTracker"));
const OpeningEditor = React.lazy(() => import("./pages/staff/OpeningEditor"));
const Openings = React.lazy(() => import("./pages/Openings"));
const OpeningInfo = React.lazy(() => import("./pages/OpeningInfo"));
const PasswordReset = React.lazy(() => import("./pages/PasswordReset"));
const PointOfSale = React.lazy(() => import("./pages/PointOfSale"));
const Charges = React.lazy(() => import("./pages/Charges"));
const Request = React.lazy(() => import("./pages/Request"));
const RequestPage = React.lazy(() => import("./pages/RequestPage"));
const RequestDefault = React.lazy(() => import("./pages/RequestDefault"));
const RequestForm = React.lazy(() => import("./pages/RequestForm"));
const Scan = React.lazy(() => import("./pages/scan"));
const Staff = React.lazy(() => import("./pages/staff"));
const StaffSearch = React.lazy(() => import("./pages/staff/Search"));
const System = React.lazy(() => import("./pages/Systems"));
const SystemImporter = React.lazy(() => import("./pages/staff/SystemImporter"));
const TableViewer = React.lazy(() => import("./pages/staff/TableViewer"));
const DbTableViewer = React.lazy(() => import("./pages/staff/dbTableViewer"));
const TechSpotlight = React.lazy(() => import("./pages/TechSpotlight"));
const ViewCounterTicket = React.lazy(() => import("./pages/ViewCounterTicket"));
const ViewProject = React.lazy(() => import("./pages/ViewProject"));
const ViewQuote = React.lazy(() => import("./pages/ViewQuote"));
const ViewWorkOrder = React.lazy(() => import("./pages/ViewWorkOrder"));
const WeeklyReport = React.lazy(() => import("./pages/staff/WeeklyReport"));
const PDFViewer = React.lazy(() => import("./components/PDFViewer"));

browserHistory.listen((location, action) => {
  window.scrollTo(0, 0);
});

if (window.location.href.includes("www.")) {
  window.location.href = window.location.href
    .replace("www.", "")
    .replace("http:", "https:");
} else if (
  window.location.protocol === "http:" &&
  !window.location.href.includes("//192.") &&
  !window.location.href.includes("//127.")
) {
  window.location.href = window.location.href.replace("http:", "https:");
} //Temp SSl Fix

export function App() {
  const settings = React.useContext(SettingsContext);
  const queryClient = useQueryClient();
  const StaySignedIn = localStorage.getItem("StaySignedIn");
  const [themeConfig, setThemeConfig] = React.useState(settings.theme);
  const [currentUserInfo, setCurrentUserInfo] = React.useState();
  const [isLoggedIn, setIsLoggedIn] = React.useState(false);
  const [isLightTheme, setIsLightTheme] = React.useState(true);

  React.useEffect(() => {
    if (localStorage.getItem("ThemePreference") === "Dark") {
      setIsLightTheme(false);
    }
  }, []);

  React.useEffect(() => {
    if (isLightTheme) {
      setThemeConfig(settings.theme);
    } else {
      setThemeConfig(settings.themeDark);
    }
  }, [isLightTheme, settings]);

  const Theme = React.useMemo(() => {
    return createTheme(themeConfig);
  }, [themeConfig]);

  const backgroundImage = `linear-gradient(135deg,${themeConfig.palette.primary.backgroundGradient1},${themeConfig.palette.primary.backgroundGradient2})`;

  React.useEffect(
    function () {
      let currentUser = localStorage.getItem("User")
        ? JSON.parse(localStorage.getItem("User"))
        : "";
      if (currentUser) {
        setCurrentUserInfo(currentUser);
        setIsLoggedIn(true);
      }
      if (StaySignedIn !== "Yes") {
        return localStorage.removeItem("User");
      }
    },
    [StaySignedIn]
  );

  const handleLogin = React.useCallback(
    (User) => {
      queryClient.invalidateQueries({ queryKey: [`auth`] });
      setCurrentUserInfo(User);
      setIsLoggedIn(true);
      localStorage.setItem("User", JSON.stringify(User));
    },
    [queryClient]
  );

  const Logout = () => {
    React.useEffect(() => {
      const LogoutFunction = async () => {
        setCurrentUserInfo();
        setIsLoggedIn(false);
        localStorage.removeItem("User");
        await fetch(`/api/logout`, {
          method: "Post",
          mode: "cors",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        });
      };
      LogoutFunction();
    }, []);
    return <Navigate to="/home" replace={true} />;
  };

  React.useEffect(() => {
    const refreshToken = async () => {
      try {
        const res = await fetch("/api/refresh_token", {
          method: "POST",
          mode: "cors",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        });
        if (res.status === 403) {
          throw new Error();
        }
      } catch (error) {
        setCurrentUserInfo();
        setIsLoggedIn(false);
        localStorage.removeItem("User");
        await fetch(`/api/logout`, {
          method: "Post",
          mode: "cors",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        });
      }
    };
    refreshToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <ThemeProvider theme={Theme}>
        <ThemeContext.Provider value={themeConfig}>
          <UserContext.Provider value={currentUserInfo}>
            <Container
              className="Print-Padding"
              maxWidth={false}
              sx={{
                position: "relative",
                textAlign: "center",
                minHeight: "100vh",
                backgroundColor: themeConfig.palette.primary.backgroundColor,
                backgroundImage: backgroundImage,
                boxSizing: "border-box",
                m: "0px",
              }}
              disableGutters
            >
              <Container
                className="Print-Padding"
                maxWidth={false}
                sx={{
                  pt: "75px",
                  pb: "45px",
                }}
              >
                <Nav
                  isLoggedIn={isLoggedIn}
                  currentUserInfo={currentUserInfo}
                  isLightTheme={isLightTheme}
                  setIsLightTheme={setIsLightTheme}
                />
                <React.Suspense fallback={<CircularIndeterminate />}>
                  <Routes>
                    {isLoggedIn ? (
                      <>
                        <Route
                          path="/"
                          element={<Navigate to="/home" replace={true} />}
                          errorElement={<Navigate to="/logout" />}
                        />
                        <Route path="/home" element={<Home />} />
                        <Route
                          path="/staffSearch"
                          element={<Navigate to="/staff" />}
                        />
                        <Route path="/staff" element={<Staff />}>
                          <Route path="" element={<StaffSearch />} />
                          <Route
                            path="accountTable"
                            element={
                              <DbTableViewer
                                View="accounts"
                                Page="/staff/accountEditor"
                              />
                            }
                          />
                          <Route
                            path="techSpotlight"
                            element={<TechSpotlight />}
                          />
                          <Route
                            path="accountEditor/:id"
                            element={<AccountEditor />}
                          />
                          <Route
                            path="customerTable"
                            element={
                              <DbTableViewer
                                View="customers"
                                Page="/staff/customerEditor"
                              />
                            }
                          />
                          <Route
                            path="customerEditor"
                            element={<CustomerEditor />}
                          />
                          <Route
                            path="customerEditor/:id"
                            element={<CustomerEditor />}
                          />
                          <Route path="paymentReport" element={<Report />} />
                          <Route
                            path="facilityEditor"
                            element={<FacilityEditor />}
                          />
                          <Route
                            path="quoteTable"
                            element={<TableViewer View="portal_quote" />}
                          />
                          <Route
                            path="openingTable"
                            element={<TableViewer View="opening" />}
                          />
                          <Route path="taskTracker" element={<TaskTracker />} />
                          <Route path="openingInfo" element={<OpeningInfo />} />
                          <Route
                            path="ticketEditor"
                            element={<PointOfSale />}
                          />
                          <Route
                            path="openingEditor"
                            element={<OpeningEditor />}
                          />
                          <Route path="dailyReport" element={<DailyReport />} />
                          <Route
                            path="weeklyReport"
                            element={<WeeklyReport />}
                          />
                          {settings?.refunds && (
                            <Route path="charges" element={<Charges />} />
                          )}
                          {settings?.purchasingPortal?.enable && (
                            <Route
                              path="purchasingPortal"
                              element={<PurchasingPortal />}
                            />
                          )}
                        </Route>
                        {settings?.purchasingPortal?.enable && (
                          <Route
                            path="purchasingPortal"
                            element={<Navigate to="/staff/purchasingPortal" />}
                          />
                        )}
                        {settings.tasks && (
                          <Route
                            path="/taskTracker"
                            element={<Navigate to="/staff/taskTracker" />}
                          />
                        )}
                        {settings.tasks && (
                          <Route
                            path="/openingInfo"
                            element={<Navigate to="/staff/openingInfo" />}
                          />
                        )}
                        <Route
                          path="/openingEditor"
                          element={<Navigate to="/staff/openingEditor" />}
                        />
                        {settings.quotes && (
                          <Route
                            path="/quotes"
                            element={<DocumentBinder cardType={"quotes"} />}
                          />
                        )}
                        {settings.projects && (
                          <Route
                            path="/projects"
                            element={<DocumentBinder cardType={"projects"} />}
                          />
                        )}
                        {settings.workOrders && (
                          <Route
                            path="/workOrders"
                            element={<DocumentBinder cardType={"workOrders"} />}
                          />
                        )}
                        {settings.serviceRequests && (
                          <>
                            <Route
                              path="/serviceRequests"
                              element={
                                <DocumentBinder cardType={"serviceRequests"} />
                              }
                            />
                            <Route
                              path="/service_Requests"
                              element={<PDFViewer />}
                            />
                          </>
                        )}
                        {settings.counterTickets && (
                          <Route
                            path="/counterTickets"
                            element={
                              <DocumentBinder cardType={"counterTickets"} />
                            }
                          />
                        )}
                        {settings.refunds && (
                          <Route
                            path="/charges"
                            element={<Navigate to="/staff/charges" />}
                          />
                        )}
                        {(settings.company === "LocDoc" ||
                          settings.company === "Demo") && (
                          <>
                            <Route path="/chart" element={<Chart />} />
                            <Route
                              path="/ticketEditor"
                              element={<Navigate to="/staff/ticketEditor" />}
                            />
                            <Route path="/cte" element={<PointOfSale />} />
                            <Route
                              path="/atrium"
                              element={
                                <RequestForm
                                  referenceName={"Atrium"}
                                  serviceContract={true}
                                />
                              }
                            />
                          </>
                        )}
                        {settings?.staffPages?.includes("Key Receipts") && (
                          <Route
                            path="/keyReceipts"
                            element={<KeyReceipts />}
                          />
                        )}
                        {settings.adminPage && (
                          <Route
                            path="/paymentReport"
                            element={<Navigate to="/staff/paymentReport" />}
                          />
                        )}
                        {settings.oms && (
                          <>
                            <Route path="/openings" element={<Openings />} />
                            <Route path="/system" element={<System />} />
                            <Route path="/oms" element={<OMSFacilities />} />
                            {settings?.facilityEditor?.facilityView && (
                              <Route
                                path="/facilityEditor"
                                element={
                                  <Navigate to="/staff/facilityEditor" />
                                }
                              />
                            )}
                          </>
                        )}
                        {settings.quotes && (
                          <Route path="/quote" element={<ViewQuote />} />
                        )}
                        <Route
                          path="/systemImporter"
                          element={<SystemImporter />}
                        />
                      </>
                    ) : (
                      <>
                        <Route
                          path="/home"
                          element={
                            <Login
                              isLoggedIn={isLoggedIn}
                              handleLogin={handleLogin}
                            />
                          }
                        />
                        <Route
                          path="/login"
                          element={
                            <Login
                              isLoggedIn={isLoggedIn}
                              handleLogin={handleLogin}
                            />
                          }
                        />
                      </>
                    )}
                    {settings.projects && (
                      <Route path="/project" element={<ViewProject />} />
                    )}
                    {settings.counterTickets && (
                      <Route
                        path="/counterTicket"
                        element={<ViewCounterTicket />}
                      />
                    )}
                    {settings.workOrders && (
                      <Route path="/workOrder" element={<ViewWorkOrder />} />
                    )}
                    {settings.API_RequestView && (
                      <>
                        <Route path="/scan" element={<Scan />} />
                        <Route path="/request" element={<RequestPage />}>
                          {settings?.serviceRequests ? (
                            <>
                              <Route
                                path=""
                                element={
                                  <Request
                                    title={"Customer Service Request"}
                                    dateText={"Request a Service Date"}
                                    handleLogin={handleLogin}
                                  />
                                }
                              />
                              <Route
                                path="service"
                                element={
                                  <Request
                                    title={"Customer Service Request"}
                                    dateText={"Request a Service Date"}
                                    handleLogin={handleLogin}
                                  />
                                }
                              />
                              <Route
                                path="quote"
                                element={
                                  <Request
                                    title={"Customer Quote Request"}
                                    dateText={"Request a Consultation Date"}
                                    handleLogin={handleLogin}
                                  />
                                }
                              />
                              <Route
                                path="serviceContract"
                                element={
                                  <RequestForm
                                    title={"Service Contract Request"}
                                  />
                                }
                              />
                              <Route
                                path="opening"
                                element={
                                  <RequestDefault handleLogin={handleLogin} />
                                }
                              />
                            </>
                          ) : (
                            <Route
                              path=""
                              element={
                                <RequestDefault handleLogin={handleLogin} />
                              }
                            />
                          )}
                        </Route>
                      </>
                    )}
                    <Route path="/passwordReset" element={<PasswordReset />} />
                    <Route path="/review" element={<Review />} />
                    <Route path="/techSpotlight" element={<TechSpotlight />} />
                    <Route path="/faq" element={<FAQ />} />
                    <Route path="/logout" element={<Logout />} />
                    <Route
                      path="*"
                      element={
                        isLoggedIn ? (
                          <Navigate to="/home" replace={true} />
                        ) : (
                          <Login
                            isLoggedIn={isLoggedIn}
                            handleLogin={handleLogin}
                          />
                        )
                      }
                    />
                  </Routes>
                </React.Suspense>
              </Container>
              <Footer />
            </Container>
          </UserContext.Provider>
        </ThemeContext.Provider>
      </ThemeProvider>
    </>
  );
}
