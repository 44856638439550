import * as React from "react";

import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Container from "@mui/material/Container";

const CircularColor = () => {
  return (
    <Container
      component="main"
      maxWidth="lg"
      sx={{
        display: "flex",
        alignSelf: "center",
        justifySelf: "center",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
        justifyItems: "center",
        width: "100%",
      }}
      disableGutters
    >
      <Box
        sx={{
          display: "flex",
          alignSelf: "center",
          justifySelf: "center",
          justifyContent: "center",
          alignContent: "center",
          alignItems: "center",
          justifyItems: "center",
          mt: "25px",
          width: "100%",
        }}
      >
        <CircularProgress
          sx={{
            alignSelf: "center",
            justifySelf: "center",
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
            justifyItems: "center",
          }}
          color="secondary"
        />
      </Box>
    </Container>
  );
};

export default CircularColor;
