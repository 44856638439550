module.exports = {
  company: "TheLab",
  companyFullName: "The Lab at Loc-Doc Security",
  // Nav Bar Options
  logo: "the-lab-logo.png",
  mainPages: ["Home", "Quotes", "Projects"], //"Downloads"
  staffPages: ["Payment Report", "Staff Search"],
  additionalPages: [], //[{ label: "", link: "" }],
  accountPages: ["Logout"],
  adminPage: true,
  //

  pageTextLayout: "center",

  // Light Theme // Color Options
  theme: {
    palette: {
      primary: {
        main: "#5B7C4B",
        text: "#111111",
        contrastText: "#efefef",
        backgroundColor: "#dfdfdf",
        backgroundGradient1: "#5B7C4B11",
        backgroundGradient2: "#fefefeaa",
      },
      secondary: {
        main: "#8AB153",
        contrastText: "#efefef",
      },
      custom: {
        main: "#967305",
        contrastText: "#efefef",
      },
      Repeater: {
        main: "#fefefe",
        contrastText: "#111111",
        headerBar: "#5B7C4B",
      },
      Icons: { main: "#5B7C4B" },
      TopBar: {
        main: "#fefefe", // Nav Bar and Login Screen Color
        contrastText: "#111111", // Login Screen Text Color
        buttonColor: "#fefefe",
      },
      contrastThreshold: 2,
      tonalOffset: 0.3,
    },
    typography: {
      fontFamily: `"Open Sans", "Helvetica", "Arial", sans-serif`,
    },
  },

  // Dark Theme
  themeDark: {
    palette: {
      primary: {
        main: "#5B7C4B",
        text: "#efefef",
        contrastText: "#efefef",
        backgroundColor: "#333333",
        backgroundGradient1: "#5B7C4B22",
        backgroundGradient2: "#5B7C4B55",
      },
      secondary: {
        main: "#8AB153",
        contrastText: "#efefef",
      },
      custom: {
        main: "#967305",
        contrastText: "#efefef",
      },
      Repeater: {
        main: "#efefef",
        contrastText: "#111111",
        headerBar: "#8AB153",
      },
      Icons: { main: "#8AB153" },
      TopBar: {
        main: "#efefef", // Nav Bar and Login Screen Color
        contrastText: "#111111", // Login Screen Text Color
        buttonColor: "#efefef",
      },
      contrastThreshold: 2,
      tonalOffset: 0.3,
    },
    typography: {
      fontFamily: `"Open Sans", "Helvetica", "Arial", sans-serif`,
    },
  },

  // Home Page and Button Options
  home: {
    imageLeft: {
      src: "img/TheLab/Lab_Left_1.webp",
      alt: "",
    },
    imageCenter: {
      src: "img/TheLab/Lab_Center_1.webp",
      alt: "",
    },
    imageRight: {
      src: "img/TheLab/Lab_Right_1.webp",
      alt: "",
    },
    skewImages: true,
    homePageButtons: [
      { label: "View Quotes", link: "/quotes/" },
      { label: "View Projects", link: "/projects/" },
      {
        label: "Book A Consult",
        link: "https://www.lab.locdoc.net/book-consult",
      },
    ],
    homePageBoxText: "",
    homePagePaperText: [
      {
        Text: ``,
      },
      {
        Text: ``,
      },
    ],
  },

  // API Data Views to send Back to
  API_DataView: "2580",
  API_DataDocument: "Reference Document",
  API_Payment: "1918",
  // View to send New Accounts To
  API_LogInView: "3144",
  API_SignUpView: "3144",
  // View to Check for Customers
  API_ContactView: "2584",

  LogInInfo: {
    firstName: "First Name",
    lastName: "Last Name",
    email: "Email",
    password: "Password",
    passwordSalt: "PasswordSalt",
  },

  // Quote Options
  quotes: {
    Title: "Quotes",
    url: "/quote/?",

    // Quote Lite View
    View: "2569",

    // Field Names for Search Repeater
    referenceNumber: { label: "Reference Number", value: "UniqID" },
    date: { label: "Date Quote Sent", value: "Date Quote Sent" },
    daysBeforeExpire: { value: 30 },
    updatedDate: { label: "Updated:", value: "Updated" },
    price: { label: "Total", value: "Grand Total" },
    enableClick: { label: "", value: "Date Quote Sent" },
    cardHeader: { label: "", value: "Project Name" },
    additionalDetails: [{ label: "", value: "" }],

    // Status Options
    status: { label: "Status", value: "Customer Portal Status" }, // Field Name to Check Status
    statusArray: [
      { label: "New", value: "Unopened" },
      { label: "Viewed", value: "Viewed" },
      { label: "Approved", value: "Approved" },
    ],

    // Quote View
    DataView: "2569",
    Customer: "Get Customer Name",
    Contact: "Contact Name",
    Facility: "Project Name",
    Location: "",
    DefaultEmail: "CalcContactEmail",

    InfoLabel: "Project Description", // Info Section Title
    Info: "Message on Invoice", // Field Name For Info Section

    // Quote Page Top Box Options (Leave at "" to Hide)
    ReferenceLabel: "Project ID:",
    ReferenceNumber: "UniqID", // Field Name Used as Reference Number
    ExpireDateLabel: "",
    ExpireDateField: "",
    Label1: "",
    Num1: "",
    Label2: "Subtotal",
    Num2: "Subtotal",
    Label3: "Tax",
    Num3: "Tax",
    Label4: "Project Total",
    Num4: "Grand Total",
    Label5: "Deposit",
    Num5: "Deposit Amount",
    paymentType: "Approve",
    approvedStatus: "Approved",

    // Quote Line Item View
    LineItemView: "2570",
    LineItemCompareField: "Content Project(id)",
    Header: "Description", // Table Header
    LineName: "Get Opening", // Field Name Used to Group Items
    Images: {
      OpeningImageView: "",
      ImageSearchField: "",
      ImageCompareField: "",
      ImageFileFields: [""],
    },
    Description: "Simple Description",
    //DescriptionReplace: [{ Replace: "", With: "" }],
    TypeField: "",
    TypeHideValues: [""],
    Quantity: "Quantity",
    "Unit Price": "Unit Price",
    "Total Price": "Total Price",

    // Relationship Name to Send Back to API Data
    API_Name: "Content Project",
    paymentName: "Link to Content Project",

    // Question PopUp Options
    QuestionTitle: "Have a Question?", // ToolTip and Question Header Text
    QuestionEmail: "Jessica@locdoc.net", // Account Manager Email
    QuestionPhone: "980-521-6121", // Account Manager Phone
  },

  // Project Options
  projects: {
    Title: "Projects",
    url: "/project/?",

    // Project Lite View
    View: "3342",

    // Field Names for Search Repeater
    referenceNumber: { label: "Reference Number", value: "UniqID" },
    date: { label: "Completed Date", value: "Date Completed" },
    updatedDate: { label: "Updated:", value: "Updated" },
    price: { label: "Total", value: "Grand Total" },
    enableClick: { label: "", value: "Date Completed" },
    cardHeader: { label: "", value: "Project Name" },
    additionalDetails: [{ label: "", value: "" }],

    // Status Options
    status: { label: "Status", value: "Project Status" }, // Field Name to Check Status
    statusArray: [
      { label: "Capture", value: "Capture" },
      { label: "Editing", value: "Editing" },
      { label: "Revision", value: "Revision" },
      { label: "Completed", value: "Completed" },
    ],
    assignedTo: { label: "Assigned To", value: "Link to Team Member" },

    // Project View
    DataView: "3342",
    Customer: "Get Customer Name",
    Contact: "Contact Name",
    Facility: "Project Name",
    Location: "",
    DefaultEmail: "CalcContactEmail",

    InfoLabel: "Project Description", // Info Section Title
    Info: "Message on Invoice", // Field Name For Info Section

    // Project Page Top Box Options (Leave at "" to Hide)
    ReferenceLabel: "Project ID:",
    ReferenceNumber: "UniqID", // Field Name Used as Reference Number
    ExpireDateLabel: "",
    ExpireDateField: "",
    Label1: "",
    Num1: "",
    Label2: "Subtotal",
    Num2: "Subtotal",
    Label3: "Tax",
    Num3: "Tax",
    Label4: "Project Total",
    Num4: "Grand Total",
    Label5: "Balance Due",
    Num5: "Remaining Balance",

    // Project Line Item View
    LineItemView: "2570",
    LineItemCompareField: "Content Project(id)",
    Header: "Description", // Table Header
    LineName: "Get Opening", // Field Name Used to Group Items
    Images: {
      OpeningImageView: "",
      ImageSearchField: "",
      ImageCompareField: "",
      ImageFileFields: [""],
    },
    Description: "Simple Description",
    //DescriptionReplace: [{ Replace: "", With: "" }],
    TypeField: "",
    TypeHideValues: [""],
    Quantity: "Quantity",
    "Unit Price": "Unit Price",
    "Total Price": "Total Price",

    // Relationship Name to Send Back to API Data
    API_Name: "Content Project",
    paymentName: "Link to Content Project",

    // Question PopUp Options
    QuestionTitle: "Have a Question?", // ToolTip and Question Header Text
    QuestionEmail: "Jessica@locdoc.net", // Account Manager Email
    QuestionPhone: "980-521-6121", // Account Manager Phone
  },

  // Staff Page Options
  Staff: {
    Email: "@locdoc.net", // What The Email Must Include to be Considered Staff
    SelectBar: ["Q", "P"], // Array of Searchable Pages // Options Include: "Q" for Quotes, "SV" for Work Orders, and "CT" for Counter Tickets
    Default: [], // Array of Pre Selected Search Pages // Options Include: "Q" for Quotes, "SV" for Work Orders, and "CT" for Counter Tickets
    SupportEmail: "Support@locdoc.net",
  },

  // Disclaimer Array Shown on Quotes Work Orders and Counter Tickets
  disclaimer: [
    // Object Pairs with labels and Text { label:  text: }
    {
      label: "Pre-Production",
      text: "Initial Meeting: discuss video story, audience, type, duration, and more",
    },
    {
      label: "",
      text: "Estimate & Proposal: a flat rate that covers cost of video production",
    },
    {
      label: "",
      text: "Production Plan: script writing, story boards, art direction, shot list organization",
    },
    {
      label: "Production",
      text: "Film : professional production crew & equipment",
    },
    {
      label: "",
      text: "Director: achieve your artistic vision of the story",
    },
    {
      label: "",
      text: "Producer: plan and coordinate film production",
    },
    {
      label: "Post-Production",
      text: "Editing: create story with key message points and revise into final cut",
    },
    {
      label: "",
      text: "Polish: once final cut is approved we color correct to brand standards",
    },
    {
      label: "",
      text: "Distribution: share in the format requested for different social standards & hard copy",
    },
  ],
  QuestionTitle: "Have a Question?",
  FinancingLabel: "", // Financing ToolTip (Leave as "" to Hide)
  FinancingLink: "", // Financing Link (Leave as "" to Hide)
  QRLabel: "", // QR Code ToolTip (Leave as "" to Hide)
  QRLink: "", // QR Link (Leave as "" to Hide)

  // Check Stub Options
  allowChecks: false,
  allCreditCards: true,

  //Address Appears on Check Stub and Below PDF Logo
  companyAddress: {
    lineOne: "The Lab",
    lineTwo: "PO Box 78987",
    lineThree: "Charlotte, NC 28271",
    phone: "(704) 554-6121",
  },

  // Purchase Order Options
  allowPurchaseOrder: true,
  purchaseOrderDocField: "",

  Stripe: {
    AllowReader: false,
    ReaderEmails: [],
    PublishableKey: "pk_live_RqLbVnaPZ9U2amTSSkZ50XHL00noZ9ljz0",
  },

  footer: {
    mainTag: "We create tools to help you grow your business.", // Footer Text
    textColor: "#efefef", // Footer Text Color
  },
};
