import React from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import { SettingsContext } from "../index";

const Footer = () => {
  const settings = React.useContext(SettingsContext);

  return (
    <Box
      component="footer"
      sx={{
        display: "flex",
        position: "absolute",
        backgroundColor: "secondary.main",
        bottom: 0,
        height: "35px",
        width: "100%",
        alignContent: "center",
        justifyContent: "center",
        borderTopLeftRadius: "15px",
        borderTopRightRadius: "15px",
        zIndex: (theme) => theme.zIndex.drawer + 1,
        displayPrint: "none",
      }}
      className="Print-Hide"
    >
      <Typography
        variant="h6"
        component="p"
        sx={{
          textAlign: "center",
          color: settings.footer.textColor,
          justifySelf: "center",
          alignSelf: "center",
          fontSize: { xs: "10pt", sm: "11pt", md: "13pt" },
          overflow: "hidden",
          whiteSpace: "nowrap",
        }}
      >
        {settings.footer.mainTag}
      </Typography>
    </Box>
  );
};

export default Footer;
